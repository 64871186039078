export const vvRequired = {
  required: true,
  message: 'Это поле обязательно для заполнения',
};

export const vvPhone = {
  len: 18,
  message: 'Телефон должен состоять из 11 цифр',
};

export const vvBinIin = {
  len: 12,
  message: 'ИИН/БИН должен состоять из 12 цифр',
};